import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import history from './history';
import Layouts from './layouts';
import './lang';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <Layouts />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
