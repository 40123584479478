import { AppLayout as AppLayoutV2 } from '_v2/layouts/app';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import AppRoute from './AppRoute';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
        />
        {protectedRoutes.map((route, index) => {
          const Layout = route.key.startsWith('dashboard-v2')
            ? AppLayoutV2
            : AppLayout;

          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <Layout>
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                </Layout>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AuthLayout>
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                </AuthLayout>
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
