import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import NavProfile from 'components/layout-components/NavProfile';
import logo from 'assets/images/sotravel-icon.png';

import { useMediaQuery } from 'react-responsive';
import { MenuOutlined } from '@ant-design/icons';
import { useIsMobile } from '_v2/hooks/responsive';

const navItems = [
  { label: 'Bookings', path: '/bookings' },
  { label: 'Listings', path: '/listings' },
  { label: 'Messages', path: '/messages' },
  { label: 'Insights', path: '/insights' },
];

function NavigationDrawer({ visible, onClose, base, isActive }) {
  return (
    <>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 z-40 ${
          visible ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      />
      <div
        className={`fixed top-0 right-0 h-full w-[250px] bg-white  z-50 transform transition-transform duration-300 ease-in-out ${
          visible ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{
          visibility: visible ? 'visible' : 'hidden',
          transition:
            'transform 0.3s ease-in-out, visibility 0s linear ' +
            (visible ? '0s' : '0.3s'),
        }}
      >
        <div className="p-4 border-b flex justify-between items-center">
          <span className="text-lg font-semibold">Menu</span>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            ✕
          </button>
        </div>
        <div className="flex flex-col gap-6 p-4">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={`${base}${item.path}`}
              className={`text-lg ${
                isActive(item.path) ? 'text-[deepskyblue]' : 'text-gray-500'
              } hover:text-[deepskyblue] transition-colors`}
              onClick={onClose}
            >
              {item.label}
            </Link>
          ))}
          <div className="mt-4 border-t pt-4">
            <NavProfile />
          </div>
        </div>
      </div>
    </>
  );
}

function MobileHeader() {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  const isActive = (path) => pathname.includes(path);
  const pathnameArray = pathname.split('/');
  const base = pathnameArray.slice(0, 1).join('/');

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  React.useEffect(() => {
    if (drawerVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [drawerVisible]);

  if (!isMobile) return null;

  return (
    <>
      <div className="flex items-center p-3 border-b">
        <img src={logo} alt="logo" className="w-8 h-8" />
        <div className="grow" />
        <button
          onClick={toggleDrawer}
          className="w-10 h-10 flex items-center justify-center hover:bg-gray-100 rounded-full transition-colors"
        >
          <MenuOutlined className="text-xl" />
        </button>
      </div>

      <NavigationDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        base={base}
        isActive={isActive}
      />
    </>
  );
}

function Header() {
  const isMobile = useIsMobile();

  if (isMobile) return <MobileHeader />;

  return <MobileDesktop />;
}

function MobileDesktop() {
  const { pathname } = useLocation();

  const isActive = (path) => pathname.includes(path);

  const pathnameArray = pathname.split('/');
  const base = pathnameArray.slice(0, 1).join('/');

  return (
    <div className="flex items-center p-3 border-b">
      <img src={logo} alt="logo" className="w-8 h-8" />
      <div className="grow flex justify-center items-center gap-4">
        {navItems.map((item) => (
          <Link
            key={item.path}
            to={`${base}${item.path}`}
            className={`${isActive(item.path) ? 'text-[deepskyblue]' : 'text-gray-500'}`}
          >
            {item.label}
          </Link>
        ))}
      </div>
      <NavProfile />
    </div>
  );
}

export default Header;
