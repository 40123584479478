import Header from '_v2/layouts/header';
import { Layout } from 'antd';
import Footer from 'components/layout-components/Footer';
import Loading from 'components/shared-components/Loading';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';

const { Content } = Layout;

export const AppLayout = ({ navCollapsed, navType, direction, children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <Content className="grow">
        <Suspense fallback={<Loading cover="content" />}>{children}</Suspense>
      </Content>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
